import React, { Suspense, lazy, useContext } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import { ButtonLink } from '../components/Button'
import { NewsLetter } from '../components/LinkCoponents'
import config from '../../data/SiteConfig'
import ThemeContext from '../context/ThemeContext'

const PostListing = lazy(() => import('../components/PostListing'))

const Layout = lazy(() => import('../layout'))

const introLineStyle = {
  margin: '20px 0',
  fontSize: '1.2rem',
}

const gameAppLinkContainerStyle = {
  marginTop: '1rem',
}

const Home = (props) => {
  const { data } = props
  const posts = [...data.latestPost.edges, ...data.posts.edges] ?? []
  const pageTitle = `${config.siteTitle} – Engineer | Writer`
  const theme = useContext(ThemeContext)

  const seoDescription = `${config.siteTitle} is a full stack software engineer specializing in modern JavaScript. This is the space where he publish his blogs on emotional health without any ads`
  return (
    <Suspense fallback={<span />}>
      <Layout title={pageTitle} seoDescription={seoDescription}>
        <div className="container">
          <div className="lead">
            <LazyMotion features={domAnimation}>
              <m.div
                animate={{
                  scale: [1, 1.2, 1.2, 1, 1],
                  rotate: [0, -3, 0, 3, 0],
                }}
                transition={{ scale: { duration: 0.01 } }}
                className="newsletter-section"
              >
                <div className="slide-container">
                  <div className="image-container">
                    <StaticImage
                      src="../../static/logos/logo-1024.webp"
                      alt={config.siteTitle}
                      title={config.siteTitle}
                      style={{
                        filter: theme.dark ? 'grayscale(100%)' : '',
                      }}
                      loading="eager"
                      className="main-picture"
                      placeholder="blurred"
                      width={280}
                      height={350}
                    />
                  </div>
                </div>
                <div>
                  <p style={introLineStyle}>
                    Engineer by profession & Writer by passion. My goal is to empower you the
                    knowledge and skills to be your greatest self. I write/post stuff on different
                    platforms. Get an update when something new comes out by signing up below!
                  </p>
                  <NewsLetter />
                </div>
              </m.div>
            </LazyMotion>
            <div className="elevator">
              <h1>Hey 👋 I&apos;m Ashmeet </h1>
              <p>
                I learn and write. My mission is to constantly learn and share the knowledge I gain
                so that you can use it to empower yourself. I&apos;m a full stack software engineer
                who loves building experiences, improving productivity, sharing ideas, solving
                problems and implementing solutions. You can know more{' '}
                <ButtonLink
                  title="About Me"
                  simpleLink
                  href="/about"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  about me here
                </ButtonLink>
                .
              </p>
            </div>
          </div>
        </div>

        <div className="container front-page" />

        <div className="gradient-section">
          <div className="container search-container">
            <div>
              <h2>Latest and Most Popular Blogs 👇</h2>
            </div>
          </div>
        </div>
        <div className="container ">
          <Suspense fallback={<span>Loading...</span>}>
            <PostListing postEdges={posts} />
            <h4 className="text-center margin-0">
              <ButtonLink
                title="See All"
                simpleLink
                href="/content"
                target="_self"
                rel="noopener noreferrer"
              >
                See All Blogs
              </ButtonLink>
            </h4>
          </Suspense>
        </div>
        <div style={gameAppLinkContainerStyle} className="container text-center">
          <h3>
            <ButtonLink
              title="Tambola Game"
              simpleLink
              href="/games/tambola"
              target="_self"
              rel="noopener noreferrer"
            >
              🎮 Play Free Tambola 🎮
            </ButtonLink>
          </h3>
        </div>
      </Layout>
    </Suspense>
  )
}

export default Home

export const postQuery = graphql`
  query {
    posts: allMarkdownRemark(
      limit: 15
      sort: { fields: { date: DESC } }
      filter: { frontmatter: { categories: { in: ["popular", "new"] } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            categories
            date
            template
          }
        }
      }
    }
    latestPost: allMarkdownRemark(limit: 1, sort: { fields: { date: DESC } }) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            categories
            date
            template
          }
        }
      }
    }
  }
`
